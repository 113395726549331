import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getFile } from '../../../Shared/Storage/storageActions';
import DashboardImagesSection from '../../../Dashboard/DashboardImagesSection';
import DashboardDescriptionSection from '../../../Dashboard/DashboardDescriptionSection';
import selectors from './homeSelectors';
import routes from '../../../../routing/routes';
import Spinner from '../../../../components/Spinner/Spinner';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../../components/InfoBox/InfoBoxRow';
import documentAgreementVersions from '../../../../enums/documentAgreementVersions';
import styles from './_home.module.scss';
import Button from '../../../../components/controls/Button';
import IconCaption from '../../../../components/IconCaption/IconCaption';
import { getMemberInsuranceInfo } from './homeActions';
import CustomLink from '../../../../components/controls/CustomLink';
import programPaymentTypes from '../../../../enums/programPaymentTypes';
import currencyFormatter from '../../../../utilities/formatters/currencyFormatter';
import * as urlFormatter from '../../../../utilities/formatters/urlFormatter';
import homeResources from './homeResources';

function Home({
    isLoading,
    hasGroupMembership,
    insuranceInfo,
    history,
    selectedMemberGroup,
    getMemberInsuranceInfo,
    getFile }) {
    useEffect(() => {
        if (selectedMemberGroup) {
            getMemberInsuranceInfo(selectedMemberGroup.membersIds);
        }
    }, []);

    const programPaymentType = useMemo(() => {
        if (!insuranceInfo) return null;
        return Number(insuranceInfo.get('insuranceInfoItems').toJS()
            .find(item => item.key === 'Program Payment Type ID').value);
    }, [insuranceInfo]);

    const hasFacilityEnrollment = useMemo(() => {
        if (!insuranceInfo) return null;
        return insuranceInfo.get('relatedFacilities').size > 0;
    }, [insuranceInfo]);

    const formatFacilityAddress = (facility) => {
        return `${facility.facilityAddress} ${facility.facilityCity}, ${facility.facilityState} ${facility.facilityZipcode}`;
    };
    if (isLoading) {
        return <Spinner />;
    } else {
        return hasGroupMembership && insuranceInfo
            ? <div className="dashboard">
                <h2 className="layout__title">{homeResources.dashboardTitle}</h2>
                <div className="dashboard__content">
                    <div className={`${styles.dashboard__description} grid__row`}>
                        <div className="col-lg-6">
                            <InfoBox className={styles.dashboard__content__widget} bordered>
                                <IconCaption icon="text_snippet" className={styles.dashboard__content__widget__box_icon} variant={'filled'}>
                                    <span className={styles.dashboard__content__widget_header}>{homeResources.groupMembershipTitle}</span>
                                </IconCaption>
                                <div className={styles.dashboard__content__widget__body}>
                                    <InfoBoxRow
                                        label={homeResources.labelPartner}
                                        labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelPartner}</div>}
                                        content={insuranceInfo.get('insuranceInfoItems').toJS()
                                            .find(item => item.key === 'Partner Name').value}
                                    />
                                    {
                                        (programPaymentType === programPaymentTypes.memberPaid || programPaymentType === programPaymentTypes.partnerMemberPaid) &&
                                            <InfoBoxRow
                                                label={homeResources.labelMonthlyDues}
                                                labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelMonthlyDues}</div>}
                                                content={currencyFormatter.formatToUSDollar(insuranceInfo.get('insuranceInfoItems').toJS()
                                                    .find(item => item.key === 'Monthly Dues').value)}
                                            />
                                    }
                                    {
                                        programPaymentType === programPaymentTypes.memberPaid &&
                                            <InfoBoxRow
                                                label={homeResources.labelRequiredVisit}
                                                labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelRequiredVisit}</div>}
                                                content={insuranceInfo.get('insuranceInfoItems').toJS()
                                                    .find(item => item.key === 'Required Visits').value}
                                            />
                                    }
                                    {
                                        programPaymentType === programPaymentTypes.memberPaid &&
                                            <InfoBoxRow
                                                label={homeResources.labelReimbursementAmount}
                                                labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelReimbursementAmount}</div>}
                                                content={currencyFormatter.formatToUSDollar(insuranceInfo.get('insuranceInfoItems').toJS()
                                                    .find(item => item.key === 'Reimbursement Amount').value)}
                                            />
                                    }
                                    {
                                        (programPaymentType === programPaymentTypes.partnerMemberPaid || programPaymentType === programPaymentTypes.memberPaid) && hasFacilityEnrollment &&
                                            <InfoBoxRow
                                                label={homeResources.labelBillingDetails}
                                                labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelBillingDetails}</div>}
                                                content={<a className="link link_color_blue" size="small" onClick={() => {history.push(routes.billing.path);}}>
                                                    {homeResources.viewText}
                                                </a>}
                                            />
                                    }
                                    {
                                        hasFacilityEnrollment &&
                                            <InfoBoxRow
                                                label={homeResources.labelMemberAgreement}
                                                labelComponent={<div className={styles.dashboard__content__widget__body__row_label}>{homeResources.labelMemberAgreement}</div>}
                                                content={
                                                    insuranceInfo.get('showMemberLegalContractLink') && insuranceInfo.get('documentAgreementVersion') >= documentAgreementVersions.V4
                                                        ? (
                                                            <a type="button" className="link link_color_blue" onClick={() => getFile(insuranceInfo.get('agreementPartitionKey'), insuranceInfo.get('agreementRowKey'))}>
                                                                {homeResources.viewText}
                                                            </a>
                                                        )
                                                        : (
                                                            insuranceInfo.get('showMemberLegalContractLink') && insuranceInfo.get('documentAgreementVersion') < documentAgreementVersions.V4 && (
                                                                <NavLink className="link link_color_blue" to={routes.memberLegalContractPage.path}>
                                                                    {homeResources.viewText}
                                                                </NavLink>
                                                            )
                                                        )
                                                }
                                            />
                                    }
                                </div>
                            </InfoBox>
                        </div>

                        <div className="col-lg-6">
                            <InfoBox className={styles.dashboard__content__widget} bordered>
                                {
                                    hasFacilityEnrollment
                                        ? (
                                            insuranceInfo.get('relatedFacilities').toJS()
                                                .map((facility, facilityIndex) => (
                                                    <React.Fragment key={facilityIndex}>
                                                        <IconCaption icon="domain" className={styles.dashboard__content__widget__box_icon} variant={'filled'}>
                                                            <span className={styles.dashboard__content__widget_header}>{homeResources.actiueClubTitle}</span>
                                                        </IconCaption>
                                                        <div className={styles.dashboard__content__widget__body}>
                                                            <span className={styles.dashboard__content__widget__body__row_label}>{facility['facilityName']}</span>
                                                            <div className={`${styles.dashboard__content__widget__body} ${styles.dashboard__content__widget__body__blue}`}>
                                                                <IconCaption icon="place" className={styles.dashboard__content__widget__box_icon_blue} variant={'filled'}>
                                                                    <CustomLink className="link link_color_blue" linkPath={urlFormatter.formatGoogleMapsUrl(formatFacilityAddress(facility))} isExternalLink isNewTab>{formatFacilityAddress(facility)}</CustomLink>
                                                                </IconCaption>
                                                                <IconCaption icon="call" className={styles.dashboard__content__widget__box_icon_blue} variant={'filled'}>
                                                                    <CustomLink className="link link_color_blue" linkPath={urlFormatter.formatPhoneUrl(facility.facilityPhone)} isExternalLink>{facility.facilityPhone}</CustomLink>
                                                                </IconCaption>
                                                                {
                                                                    facility.facilityWebsite && <IconCaption icon="public" className={styles.dashboard__content__widget__box_icon_blue} variant={'filled'}>
                                                                        {<CustomLink className="link link_color_blue" linkPath={urlFormatter.formatFacilityUrl(facility.facilityWebsite)} isExternalLink isNewTab>{facility.facilityWebsite}</CustomLink>}
                                                                    </IconCaption>
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                        )
                                        : (
                                            <>
                                                <IconCaption icon="fitness_center" className={styles.dashboard__content__widget__box_icon} variant={'filled'}>
                                                    <span className={styles.dashboard__content__widget_header}>{homeResources.enrollInFacilityTitle}</span>
                                                </IconCaption>
                                                <div className={styles.dashboard__content__widget__body}>
                                                    <span>{homeResources.noFacilitiesEnrolledText}</span>
                                                    <div className={styles.dashboard__content__widget__body__actions}>
                                                        <Button theme="new--blue" size="small" onClick={() => {
                                                            history.push(routes.facilityLocator.path);
                                                        }}
                                                        >
                                                            {homeResources.findFacilityButtonText}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                }
                            </InfoBox>
                        </div>
                    </div>
                </div>
            </div>
            : (<div className="dashboard">
                <h2 className="layout__title">Healthy Contributions Portal</h2>
                <div className="dashboard__content">
                    <h4 className="dashboard__heading">Hello!</h4>
                    <div className="dashboard__description">
                        <p>
                            Welcome to your own personal Healthy Contributions portal and
                            dashboard. We are excited and pleased to have you here!
                        </p>
                        <p>
                            Take a look around to see your facility, your personal information,
                            and updates we may have for you regarding your monthly fitness
                            reimbursement. We care about our individual members and want you to
                            have the best possible experience while navigating your HC portal.
                        </p>
                        <p>
                            We hope you enjoy checking on your own information, updating your
                            own banking when needed and taking your fitness benefit to the next
                            level of satisfaction. Your Healthy Contributions team is here to
                            help you as well. Don’t hesitate to reach out!
                        </p>
                        <DashboardImagesSection />
                    </div>
                    <DashboardDescriptionSection />
                </div>
            </div>);
    }
}

Home.propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasGroupMembership: PropTypes.bool.isRequired,
    insuranceInfo: PropTypes.object.isRequired,
    getMemberInsuranceInfo: PropTypes.func.isRequired,
    selectedMemberGroup: PropTypes.shape({
        membersIds: PropTypes.array,
    }),
    getFile: PropTypes.func,
};

const mapDispatchToProps = {
    getMemberInsuranceInfo,
    getFile
};

export default connect(selectors, mapDispatchToProps)(Home);
