import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

function FooterMenuItem({ id, description, active, children }) {
    const defaultClassName = 'footer-menu-item';
    const activeClassName = `${defaultClassName}--active`;
    const divId = `${id}-header`;
    const listId = `${id}-list`;

    const divRef = React.useRef();

    const [isActive, setActive] = React.useState(active);

    const handleKeypress = (e) => {
        //  keyboard enter (13) and space (32) for keyboard users
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();

            divRef.current.click();
        }
    };

    return (
        <li id={listId} className={classNames([defaultClassName, { [activeClassName]: isActive }])} >
            <h2>
                <div id={divId} ref={divRef} className="footer-menu-item__text" onClick={() => setActive(!isActive)}
                    role="button"
                    tabIndex="0"
                    onKeyUp={handleKeypress}
                    aria-controls={listId} aria-expanded={isActive ? 'true' : 'false'}
                >
                    <label className="footer-menu-item__label">{description}</label>
                    <MaterialIcon id={id} className="footer-menu-item__expand-icon" icon="expand_more" ariaHidden filterExpandContentId={listId} />
                </div>
            </h2>
            {children}
        </li>
    );
}

FooterMenuItem.propTypes = {
    id: PropTypes.string.isRequired,
    childrenId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    active: PropTypes.bool,
    children: PropTypes.element.isRequired,
};

export default FooterMenuItem;