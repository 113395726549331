import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiInfoBox from '../../../../components/InfoBox/MuiInfoBox';
import FormGroup from '../../../../components/controls/FormGroup';
import { Field, reduxForm, reset, change } from 'redux-form/immutable';
import MuiInputSelect from '../../../../components/controls/MuiInputSelect';
import { MuiInputText } from '../../../../components/controls/MuiInputText';
import { MuiInputCurrency } from '../../../../components/controls/MuiInputCurrency';
import { MuiInputNumeric } from '../../../../components/controls/MuiInputNumeric';
import Button from '../../../../components/controls/Button';
import { defaultFormProperties } from '../../../../utilities/form';
import groupMembershipsInfoResources, { GetLaunchDateMinValue, GetLaunchDateMaxValue } from './groupMembershipsInfoResources';
import * as validationRules from './groupMembershipsInfoValidations';
import asyncValidation from '../../../../utilities/asyncFormValidation';
import { getKeyfobsCount } from '../groupMembershipsActions';
import resources from '../../../Shared/resources';
import programPaymentTypes from '../../../../enums/programPaymentTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import reimbursementTypes from '../../../../enums/reimbursementTypes';
import MuiInputDatePicker from '../../../../components/controls/MuiInputDatePicker';
import './_group-memberships-info-form.scss';

function GroupMembershipsInfoForm(
    {
        pristine,
        submitting,
        handleSubmit,
        dispatch,
        form,
        intialProgramPaymentType,
        isExisting,
        hasEnrolledOrInvitedMembers
    }) {
    const [programPaymentType, setProgramPaymentType] = useState(-1);
    const [onPartnerProgramSettingsEditMode, setOnPartnerProgramSettingsEditMode] = useState(false);
    const [onMemberSettingsEditMode, setOnMemberSettingsEditMode] = useState(false);
    const { gmrAddProgram } = useFlags();
    const [isDisabledReimbursementField, setIsDisabledReimbursementField] = useState(false);
    useEffect(() => {
        setProgramPaymentType(intialProgramPaymentType);
    }, [isExisting]);
    function handleChange(event) {
        setProgramPaymentType(event.target.value);
        setOnPartnerProgramSettingsEditMode(true);
        setOnMemberSettingsEditMode(true);
    }
    function handlePartnerProgramSettingsEdit(event) {
        event.stopPropagation();
        if (onMemberSettingsEditMode || onPartnerProgramSettingsEditMode) {
            dispatch(reset(form));
        }
        setOnMemberSettingsEditMode(false);
        setOnPartnerProgramSettingsEditMode(!onPartnerProgramSettingsEditMode);
    }
    function handleMemberSettingsEdit(event) {
        event.stopPropagation();
        if (onMemberSettingsEditMode || onPartnerProgramSettingsEditMode) {
            dispatch(reset(form));
        }
        setOnPartnerProgramSettingsEditMode(false);
        setOnMemberSettingsEditMode(!onMemberSettingsEditMode);
    }
    function readOnlyPartnerProgramSettingsMode() {
        return !onPartnerProgramSettingsEditMode && programPaymentType > 0;
    }
    function readOnlyMemberSettingsMode() {
        return !onMemberSettingsEditMode && programPaymentType > 0;
    }
    const handleBillingTypeChange = (event) => {
        if (event.target.value === reimbursementTypes.noReimbursements) {
            setIsDisabledReimbursementField(true);
            dispatch(change(form, groupMembershipsInfoResources.fieldReimbursementAmount, 0));
            dispatch(change(form, groupMembershipsInfoResources.fieldNumberOfVisitsToReimburse, 0));
        }
        else {
            setIsDisabledReimbursementField(false);
            dispatch(change(form, groupMembershipsInfoResources.fieldReimbursementAmount, null));
            dispatch(change(form, groupMembershipsInfoResources.fieldNumberOfVisitsToReimburse, null));
        }
    };
    return (
        <FormGroup onSubmit={handleSubmit} name="groupMembershipsForm">
            <div className="group-memberships-info-form">
                <div className="grid__row align-items-center">
                    <div className="col col-lg-4 col-md-10 d-flex float-left">
                        <Field
                            id="program_payment_type"
                            labelId="program_payment_type_label"
                            name={groupMembershipsInfoResources.fieldProgramPaymentType}
                            component={MuiInputSelect}
                            label={groupMembershipsInfoResources.labelProgramPaymentType}
                            options={groupMembershipsInfoResources.programPaymentTypeOptions}
                            getOptionLabel={option => (option ? option.label : '')}
                            getOptionValue={option => (option ? option.value : undefined)}
                            validate={[
                                validationRules.programPaymentTypeRequired
                            ]}
                            readOnly={intialProgramPaymentType > 0}
                            onChange={handleChange}
                            defaultValue={undefined}
                        />
                    </div>
                    {!(isExisting) && gmrAddProgram && (
                        <div className="col col-lg-1 col-md-2 col-sm-4 d-flex float-left">
                            <Button
                                type="submit"
                                size="small"
                                disabled={pristine || submitting}
                                theme="new btn_new--blue"
                                className="btn_new--squared btn_new--small no-margin"
                            >
                                {resources.saveButtonText}
                            </Button>
                        </div>)}
                </div>
                {programPaymentType > 0 && (
                    <MuiInfoBox expandable defaultExpanded bordered
                        name="partnerProgramSettings"
                        showEditButton={isExisting}
                        title={groupMembershipsInfoResources.labelPartnerSettings}
                        className="height-auto"
                        onEdit={handlePartnerProgramSettingsEdit}
                    >
                        <div className="grid__row">
                            {programPaymentType === programPaymentTypes.partnerMemberPaid && (
                                <div className="col col-lg-4 col-md-6 d-flex float-left">
                                    <Field
                                        label={groupMembershipsInfoResources.labelDuesForOneMemberPerMonthPaidToFacility}
                                        name={groupMembershipsInfoResources.fieldDuesForOneMemberPerMonthPaidToFacility}
                                        component={MuiInputCurrency}
                                        validate={[
                                            validationRules.duesForOneMemberPerMonthPaidToFacilityRequired,
                                            validationRules.duesForOneMemberPerMonthPaidToFacilityRange,
                                        ]}
                                        readOnly={readOnlyPartnerProgramSettingsMode()}
                                    />
                                </div>)}
                            {programPaymentType === programPaymentTypes.partnerPaid && (
                                <div className="col col-lg-4 col-md-6 d-flex float-left">
                                    <Field
                                        label={groupMembershipsInfoResources.labelProgramFee}
                                        name={groupMembershipsInfoResources.fieldProgramFee}
                                        component={MuiInputCurrency}
                                        validate={[
                                            validationRules.programFeeRequired,
                                            validationRules.programFeeRange,
                                            validationRules.programFeeGreaterThanNumberOfMembershipsAndDuesForOneMemberPerMonthPaidToFacilityRequiredMultiplication
                                        ]}
                                        readOnly={readOnlyPartnerProgramSettingsMode()}
                                    />
                                </div>)}
                            <div className="col col-lg-4 col-md-6 d-flex float-left">
                                <Field
                                    label={groupMembershipsInfoResources.labelAdminFee}
                                    name={groupMembershipsInfoResources.fieldAdminFee}
                                    component={MuiInputCurrency}
                                    validate={[
                                        validationRules.adminFeeRequired,
                                        validationRules.adminFeeRange,
                                    ]}
                                    readOnly={readOnlyPartnerProgramSettingsMode()}
                                />
                            </div>
                            <div className="col col-lg-4 col-md-6 d-flex float-left">
                                <Field
                                    label={groupMembershipsInfoResources.labelNumberOfMemberships}
                                    name={groupMembershipsInfoResources.fieldNumberOfMemberships}
                                    component={MuiInputNumeric}
                                    validate={[
                                        validationRules.numberOfMembershipsRequired,
                                        validationRules.numberOfMembershipsRange
                                    ]}
                                    readOnly={readOnlyPartnerProgramSettingsMode()}
                                />
                            </div>
                            {programPaymentType === programPaymentTypes.partnerPaid && (
                                <div className="col col-lg-4 col-md-6 d-flex float-left">
                                    <Field
                                        label={groupMembershipsInfoResources.labelDuesForOneMemberPerMonthPaidToFacility}
                                        name={groupMembershipsInfoResources.fieldDuesForOneMemberPerMonthPaidToFacility}
                                        component={MuiInputCurrency}
                                        validate={[
                                            validationRules.duesForOneMemberPerMonthPaidToFacilityRequired,
                                            validationRules.duesForOneMemberPerMonthPaidToFacilityRange,
                                        ]}
                                        readOnly={readOnlyPartnerProgramSettingsMode()}
                                    />
                                </div>)}
                            <div className="col col-lg-4 col-md-6 d-flex float-left">
                                <Field
                                    label={groupMembershipsInfoResources.labelLaunchDate}
                                    name={groupMembershipsInfoResources.fieldLaunchDate}
                                    component={MuiInputDatePicker}
                                    minDate={GetLaunchDateMinValue()}
                                    maxDate={GetLaunchDateMaxValue()}
                                    validate={onPartnerProgramSettingsEditMode && !hasEnrolledOrInvitedMembers
                                        ? [
                                            validationRules.launchDateRequired,
                                            validationRules.launchDateRange
                                        ]
                                        : []}
                                    readOnly={readOnlyPartnerProgramSettingsMode() || hasEnrolledOrInvitedMembers}
                                />
                            </div>
                        </div>
                        {isExisting && onPartnerProgramSettingsEditMode && (
                            <div className="grid__row action-buttons">
                                <div className="col d-flex float-left">
                                    <Button
                                        type="submit"
                                        size="small-fixed"
                                        disabled={pristine || submitting || !onPartnerProgramSettingsEditMode}
                                        theme="new btn_new--blue"
                                        className="btn_new--squared btn_new--small"
                                    >
                                        {resources.saveChangesButtonText}
                                    </Button>
                                </div>
                                <div className="col d-flex float-left">
                                    <Button
                                        onClick={handlePartnerProgramSettingsEdit}
                                        type="button"
                                        size="small-fixed"
                                        disabled={pristine || submitting || !onPartnerProgramSettingsEditMode}
                                        theme="new btn_new--white"
                                        className="btn_new--squared btn_new--small"
                                    >
                                        {resources.cancelButtonText}
                                    </Button>
                                </div>
                            </div>)}
                    </MuiInfoBox>)}
                {(programPaymentType === programPaymentTypes.memberPaid
                    || programPaymentType === programPaymentTypes.partnerMemberPaid)
                    && gmrAddProgram && (
                        <MuiInfoBox expandable defaultExpanded bordered
                            name="memberSettings"
                            showEditButton={isExisting}
                            title={groupMembershipsInfoResources.labelMemberSettings}
                            className="height-auto"
                            onEdit={handleMemberSettingsEdit}
                        >
                            <div className="grid__row">
                                <div className="col col-lg-4 col-md-6 d-flex float-left">
                                    <Field
                                        label={groupMembershipsInfoResources.labelDuesAmount}
                                        name={groupMembershipsInfoResources.fieldDuesAmount}
                                        component={MuiInputCurrency}
                                        readOnly={readOnlyMemberSettingsMode()}
                                        validate={[
                                            validationRules.duesAmountRequired,
                                            validationRules.duesAmountRange
                                        ]}
                                    />
                                </div>
                            </div>
                            {programPaymentType === programPaymentTypes.memberPaid
                                && (
                                    <div className="grid__row">
                                        <div className="col col-lg-4 col-md-6 d-flex float-left">
                                            <Field
                                                id="member_reimbursement_type_name"
                                                labelId="member_reimbursement_type_name_label"
                                                label={groupMembershipsInfoResources.labelReimbursementType}
                                                name={groupMembershipsInfoResources.fieldMemberReimbursementType}
                                                component={MuiInputSelect}
                                                options={groupMembershipsInfoResources.reimbursementTypeOptions}
                                                getOptionLabel={option => (option ? option.label : '')}
                                                getOptionValue={option => (option ? option.value : undefined)}
                                                validate={[
                                                    validationRules.memberReimbursementTypeRequired,
                                                    validationRules.reimbursementTypeGreater
                                                ]}
                                                readOnly={readOnlyMemberSettingsMode()}
                                                onChange={handleBillingTypeChange}
                                            />
                                        </div>
                                        <div className="col col-lg-4 col-md-6 d-flex float-left">
                                            <Field
                                                label={groupMembershipsInfoResources.labelReimbursementAmount}
                                                name={groupMembershipsInfoResources.fieldReimbursementAmount}
                                                component={MuiInputCurrency}
                                                validate={[
                                                    validationRules.reimbursementAmountRequired,
                                                    validationRules.reimbursementAmountRange
                                                ]}
                                                readOnly={readOnlyMemberSettingsMode() || isDisabledReimbursementField}
                                            />
                                        </div>
                                        <div className="col col-lg-4 col-md-6 d-flex float-left">
                                            <Field
                                                label={groupMembershipsInfoResources.labelNumberOfVisitsToReimburse}
                                                name={groupMembershipsInfoResources.fieldNumberOfVisitsToReimburse}
                                                component={MuiInputNumeric}
                                                validate={[
                                                    validationRules.numberOfVisitsToReimburseRequired,
                                                    validationRules.numberOfVisitsToReimburseRange
                                                ]}
                                                readOnly={readOnlyMemberSettingsMode() || isDisabledReimbursementField}
                                            />
                                        </div>
                                    </div>)}
                            {isExisting && onMemberSettingsEditMode && (
                            <div className="grid__row action-buttons">
                                <div className="col d-flex float-left">
                                    <Button
                                        type="submit"
                                        size="small-fixed"
                                        disabled={pristine || submitting || !onMemberSettingsEditMode}
                                        theme="new btn_new--blue"
                                        className="btn_new--squared btn_new--small"
                                    >
                                        {resources.saveChangesButtonText}
                                    </Button>
                                </div>
                                <div className="col d-flex float-left">
                                    <Button
                                        onClick={handleMemberSettingsEdit}
                                        type="button"
                                        size="small-fixed"
                                        disabled={pristine || submitting || !onMemberSettingsEditMode}
                                        theme="new btn_new--white"
                                        className="btn_new--squared btn_new--small"
                                    >
                                        {resources.cancelButtonText}
                                    </Button>
                                </div>
                            </div>)}
                        </MuiInfoBox>)
                }
                {programPaymentType > 0 && (
                    <MuiInfoBox expandable defaultExpanded bordered
                        name="facilitySettings"
                        title={groupMembershipsInfoResources.labelFacilitySettings}
                        className="height-auto"
                    >
                        <div className="grid__row">
                            <div className="col col-lg-4 col-md-6 d-flex float-left">
                                <Field
                                    label={groupMembershipsInfoResources.labelReimbursementType}
                                    name={groupMembershipsInfoResources.fieldReimbursementType}
                                    component={MuiInputText}
                                    readOnly
                                />
                            </div>
                            <div className="col col-lg-4 col-md-6 d-flex float-left">
                                <Field
                                    label={groupMembershipsInfoResources.labelFeesBillingType}
                                    name={groupMembershipsInfoResources.fieldFeesBillingType}
                                    component={MuiInputText}
                                    readOnly
                                />
                            </div>
                        </div>
                    </MuiInfoBox>)
                }
            </div>
        </FormGroup>
    );
}

GroupMembershipsInfoForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    form: PropTypes.string,
    intialProgramPaymentType: PropTypes.number,
    isExisting: PropTypes.bool.isRequired,
    isGmrFeatureEnabled: PropTypes.bool,
    hasEnrolledOrInvitedMembers: PropTypes.bool
};

export default reduxForm({
    form: 'groupMembershipsInfoForm',
    asyncValidate: (values, dispatch) => asyncValidation(values, dispatch, getKeyfobsCount, validationRules.numberOfMembershipsValidation),
    asyncBlurFields: ['numberOfMemberships'],
    ...defaultFormProperties
})(GroupMembershipsInfoForm);
